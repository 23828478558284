<template>
	<div class="main-contents">
		<div class="tit"><SelectComp type="text" cdId="PRO101" :value="$store.state.userInfo.mberDivCd"/>/ {{$store.state.userInfo.mberNm}}님 반갑습니다!</div>
		<div class="myinfo_btn" @click="pupupMyInfo">내정보 보기 &#9654;</div>
		<div class="top">
			<div class="box">
				<div class="item">
					<div class="item_tit"><img src="/images/admin/main_icon_01.png" alt="이력서미등록" @click="$router.push({name:'MEM910M01', params:{resmYn:'N'}})"/></div>
					<div class="count">{{data.tecNoResm}}</div><div class="new" v-if="data.tecNoResm > 0">N</div>
				</div>
				<div class="item">
					<div class="item_tit"><img src="/images/admin/main_icon_03.png" alt="기술등급미평가" @click="$router.push({name:'MEM910M01', params:{confirmYn:'N'}})"/></div>
					<div class="count">{{data.tecResmNoCfm}}</div><div class="new" v-if="data.tecResmNoCfm > 0">N</div>
				</div>
			</div>
			<div class="box">
				<div class="item">
					<div class="item_tit"><img class="main_icon_04" src="/images/admin/main_icon_04.png" alt="하이프로기술인재미평가" @click="$router.push({name:'MEM912M01', params:{pronEvalYn:'N'}})"/></div>
					<div class="count">{{data.tecNoEval}}</div><div class="new" v-if="data.tecNoEval > 0">N</div>
				</div>
					<div class="item">
					<div class="item_tit"><img class="main_icon_06" src="/images/admin/main_icon_06.png" alt="수요기업기술인재미평가" @click="$router.push({name:'MEM911M01', params:{evalYn:'N'}})"/></div>
					<div class="count">{{data.intvNoEval}}</div><div class="new" v-if="data.intvNoEval > 0">N</div>
				</div>
			</div>
			<div class="box">
				<div class="item">
					<div class="item_tit"><img src="/images/admin/main_icon_05_GMG.png" alt="수요기업미승인" @click="$router.push({name:'MEM923M01', params:{apprDiv:'02'}})"/></div>
					<div class="count">{{data.gmgAppr}}</div><div class="new" v-if="data.gmgAppr > 0">N</div>
				</div>
				<!-- 공급기업 관리자 승인 -->
				<div class="item">
					<div class="item_tit"><img src="/images/admin/main_icon_05_SCO.png" alt="공급기업미승인" @click="$router.push({name:'MEM930M01', params:{apprDiv:'02'}})"/></div>
					<div class="count">{{data.scoAppr}}</div><div class="new" v-if="data.scoAppr > 0">N</div>
				</div>
			</div>	
		</div>
		<div class="bottom">
			<div class="box">
				<div class=box_title>
					공급기업 지정 프로젝트
				</div>
					<!-- to-be: 공급기업 프로젝트 카운트 가져오기 및 이미지 변경  -->
				<div class="item">
				<div class="item_tit"><img src="/images/admin/main_notAppr.png" alt="미승인 프로젝트" @click="$router.push({name:'PRJ920M01', params:{projectApprStatus:'1'}})"/></div>
					<div class="count">{{data.scoPrjNotapp}}</div><div class="new" v-if="data.scoPrjNotapp > 0">N</div>
				</div>
				<div class="item">
					<div class="item_tit"><img src="/images/admin/main_proposal.png" alt="제안단계프로젝트" @click="$router.push({name:'PRJ924M01'})"/></div>
					<div class="count">{{data.scoPrjPro}}</div><div class="new" v-if="data.scoPrjPro > 0">N</div>
				</div>
				<div class="item">
					<div class="item_tit"><img src="/images/admin/main_upcoming.png" alt="수행예정프로젝트" @click="$router.push({name:'PRJ925M01', params:{projectStatusTemp:'2'}})"/></div>
					<div class="count">{{data.scoPrjBef}}</div><div class="new" v-if="data.scoPrjBef > 0">N</div>
				</div>	
				<div class="item">
					<div class="item_tit main_icon_08"><img src="/images/admin/main_active.png" alt="수행중인프로젝트" @click="$router.push({name:'PRJ922M01'})"/></div>
					<div class="count">{{data.scoPrjNow}}</div><div class="new" v-if="data.scoPrjNow > 0">N</div>
				</div>
			</div>
			<div class="box">
				<div class=box_title>
					일반 프로젝트
				</div>
				<div class="item">
					<div class="item_tit"><img src="/images/admin/main_notAppr.png" alt="미승인프로젝트" @click="$router.push({name:'PRJ910M01', params:{projectApprStatus:'1'}})"/></div>
					<div class="count">{{data.prjNotapp}}</div><div class="new" v-if="data.prjNotapp > 0">N</div>
				</div>
				<!-- to-be: 제안단계 프로젝트 데이터 가져오기 및 이미지 변경  -->
				<div class="item">
					<div class="item_tit"><img src="/images/admin/main_proposal.png" alt="제안단계프로젝트" @click="$router.push({name:'PRJ914M01'})"/></div>
					<div class="count">{{data.prjPro}}</div><div class="new" v-if="data.prjPro > 0">N</div>
				</div>
				<div class="item">
					<div class="item_tit"><img src="/images/admin/main_upcoming.png" alt="수행예정프로젝트" @click="$router.push({name:'PRJ915M01', params:{projectStatusTemp:'2'}})"/></div>
					<div class="count">{{data.prjBef}}</div><div class="new" v-if="data.prjBef > 0">N</div>
				</div>
				<div class="item">
					<div class="item_tit main_icon_08"><img src="/images/admin/main_active.png" alt="수행중인프로젝트" @click="$router.push({name:'PRJ912M01'})"/></div>
					<div class="count">{{data.prjNow}}</div><div class="new" v-if="data.prjNow > 0">N</div>
				</div>
			</div>
			<div class="memo">
				<div class="title">
					MEMO
				</div>
				<img src="/images/memo_plus_btn.png" alt="메모추가"  @click="memo.unshift({edit: {}})"/>
				<div class="scroll">
					<!-- 메모추가버튼클릭시 -->
					
					<!-- 메모작성란 추가 -->
					<div class="memo_layout" v-for="(item, idx) in memo" :key="getKey(item, idx)" :class="{writen : isEdit(item)}">
						<div class="left">
							<div class="modify_btn">
								<template  v-if="isEdit(item)">
									<img src="/images/main_memo_save.png" alt="저장버튼" @click="saveMemo(item, idx)"/>
									<img src="/images/memo_cancel.png" alt="취소버튼" @click="item.memoSeq ? $set(item, 'edit', false) : deleteMemo(item, idx)"/>
								</template>
								<template  v-else>
									<img src="/images/memo_correct.png" alt="수정버튼" @click="$set(item, 'edit', Object.assign({}, item))"/>
									<img src="/images/memo_delete.png" alt="삭제버튼" @click="deleteMemo(item, idx)"/>
								</template>
							</div>
							<div class="date_and_writer">
								<div class="date">{{item.regDate | date('yyyy.MM.dd')}}</div>
								<div class="writer">작성자/{{item.regMberNm || $store.state.userInfo.mberNm}}</div>
							</div>
							<div class="contents">
								<template  v-if="isEdit(item)">
									<InputComp type="textarea" title="내용" placeholder="내용을 입력해주세요" v-model="item.edit.memoCont"/>
								</template>
								<template  v-else>
									{{item.memoCont}}	
								</template>
							</div>
						</div>
					</div>
						
					
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			data: {},
			memo: [],
			nextKey : '',
		};
	},
	mounted(){
		this.$emit('setViewConfig', { classNm: 'main' });
		this.$.httpPost('/api/main/adm/adminMain')
			.then(res => {
				//console.log(res.data);
				this.data = res.data;
			}).catch(this.$.httpErrorCommon);
		this.getMemo();
	},
	destroyed(){
		this.$emit('clearViewConfig')
	},
	methods: {
		getMemo(){
			if(typeof this.nextKey != 'string') return;
			var nextKey = this.nextKey;
			this.$.httpPost('/api/main/adm/getMemo', { nextKey })
				.then(res => {
					this.memo = this.memo.concat(res.data.list);
					this.nextKey = res.data.nextKey;
				}).catch(this.$.httpErrorCommon);
		},
		isEdit(item){
			return typeof item.edit == 'object'
		},
		getKey(item, index){
			return item.memoSeq || ('Temp' + index);
		},
		pupupMyInfo(){
			var div = 'agt'
			var reqMberSeq = this.$store.state.userInfo.mberSeq;
			this.$.popup('/adm/mem/MEM923P02', { reqMberSeq, div });
		},

		saveMemo(item){
			//console.log('saveMemo', item, idx);
			if(!item.edit) return alert('저장할 정보가 없습니다.')
			var input = item.edit;
			this.$.httpPost('/api/main/adm/saveMemo', input)
				.then(res => {
					item.edit = false;
					item.memoSeq   = res.data.memoSeq;
					item.memoTitle = res.data.memoTitle;
					item.memoCont  = res.data.memoCont;
					item.memoStatusCd = res.data.memoStatusCd;
					item.regMberSeq = item.regMberSeq || this.$store.state.userInfo.mberSeq;
					item.regMberNm  = item.regMberNm  || this.$store.state.userInfo.mberNm;
					item.regDate    = item.regDate    || new Date().format('yyyyMMdd');
				});
		},
		deleteMemo(item, idx){
			//console.log('deleteMemo', item, idx);
			if(item.memoSeq){
				var memoSeq    = item.memoSeq;
				this.$.httpPost('/api/main/adm/deleteMemo', {memoSeq})
					.then(() => {
						for(var i in this.memo){
							if(this.memo[i].memoSeq == memoSeq){
								this.memo.splice(i, 1);
							}
						}
					});
			} else {
				this.memo.splice(idx, 1);
			}
		},

		handleScroll(){
			this.getMemo();
		}
	},
};
</script>
